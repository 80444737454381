import i18n from "@/i18n/i18n";

export const Questions = [
  {
    id: 1,
    title :  i18n.t('faq.question_1.question'),
    response:  i18n.t('faq.question_1.text'),
  },
  {
    id: 2,
    title :  i18n.t('faq.question_2.question'),
    response:  i18n.t('faq.question_2.text'),
  },
  {
    id: 3,
    title :  i18n.t('faq.question_3.question'),
    response:  i18n.t('faq.question_3.text'),
  },
  {
    id: 4,
    title :  i18n.t('faq.question_4.question'),
    response:  i18n.t('faq.question_4.text'),
  }
];
