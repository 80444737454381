<template>
  <HomeLayout>
    <template #content>
      <div class="space-y-10">
        <div
          ref="landing"
          class="relative md:h-screen"
        >
          <div class="w-full md:w-8/12 xl:w-1/2 2xl:w-3/5 h-full mt-8 md:mt-0 md:float-right flex items-center z-10">
            <div class="w-full md:mb-48 float-right text-center">
              <h1 class="geminis text-6xl md:text-7xl lg:text-8xl 2xl:text-9xl text-shadow mb-14">
                {{ $t('faq.title.part1') }}   <br>
                {{ $t('faq.title.part2') }}
              </h1>
            </div>
          </div>
          <div class="hidden md:block bg-astronaute mt-24 h-full bg-no-repeat bg-contain" />
        </div>
        <div class="space-y-10 lg:px-28">
          <FaqCard v-for="question in faqQuestions" :key="question.id"  :question="question"/>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="w-full flex flex-col items-center justify-center">
        <img
          :data-src="require('@/assets/images/dotcode.png')"
          alt="Dotcode"
          class="z-10 w-1/2 md:w-auto"
        >
        <div class="geminis">
          Made with love by l'équipe 12
        </div>
        <div class="geminis">
          2022, Dotcode
        </div>
      </div>
    </template>
  </HomeLayout>
</template>

<script>
import HomeLayout from '@/layouts/HomeLayout';
import FaqCard from '@/components/Edito/FaqCard';
import {Questions} from '@/constants/Faq/FaqConstants';

export default {
  name: 'Faq',
  components: {
    FaqCard,
    HomeLayout
  },
  data () {
    return {
      faqQuestions : Questions
    };
  }

};
</script>

<style>
.bg-astronaute {
  background: url("~@/assets/images/welcome-on-board.svg");
  background-repeat: no-repeat;
}
</style>
