<template>
  <div>
    <div class="flex justify-between border-b p-2 cursor-pointer" @click="setIdQuestion">
      <h2 class="geminis md:text-3xl">{{ question.title }}</h2>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-7 h-7">
        <path v-if="!isShow" stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        <path v-else stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
      </svg>

    </div>
    <div
      class="py-8 px-1 border-b-2 border-white"
      :class="{'hidden' : !isShow, 'block': isShow}">
      <p v-html="question.response" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqCard',
  props: {
    question : {
      type : Object,
      required : true
    }
  },
  data() {
    return {
      idShow: null
    };
  },
  computed : {
    isShow() {
      return this.idShow === this.question.id;
    }
  },
  methods : {
    setIdQuestion () {
      this.idShow = this.idShow === this.question.id
        ? this.idShow = null
        : this.idShow = this.question.id;
    }
  }
};
</script>

<style scoped>

</style>
